import Vue from 'vue'
import VueRouter from 'vue-router'

const Active = () => import('../views/Active')
const Lottery = () => import('../views/Lottery')
const Reward = () => import('../views/Reward')

Vue.use(VueRouter)

const routes = [
  {
    path: '/active',
    name: 'Active',
    component: Active
  },
  {
    path: '/lottery',
    name: 'Lottery',
    component: Lottery
  },
  {
    path: '/reward',
    name: 'Reward',
    component: Reward
  },
  { path: '*', redirect: '/active' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
