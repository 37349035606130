import Qrcode from '@/components/Qrcode.vue'

export const shareConfig = function shareConfig() {
  return {
    title: '分享有礼',
    page: 'active',
    showKey: 'shareShow',
    customPage: (h) => {
      const { inviteCode, shareLink } = this.shareCode
      return (
        <div class="share">
          <div class="invite-code">
            <p>{`邀请码：${inviteCode}`}</p>
            <img onClick={(e) => this.copyCode(inviteCode, '邀请码已复制')} src={require('@/assets/img/icon/copy-btn.png')}/>
          </div>
          <Qrcode class="QR-code" type="image" value={shareLink}></Qrcode>
          <div class="share-icon">
            <img onClick={(e) => {this.openApp('wechat')}} src={require('@/assets/img/icon/weixin-icon.png')}/>
            <img onClick={(e) => {this.openApp('qq')}} src={require('@/assets/img/icon/qq-icon.png')}/>
          </div>
        </div>
      )
    }
  }
}

export const headerConfig = function headerConfig() {
  return {
    page: 'active',
    title: '活动结束倒计时',
    banner: this.banner,
    packName: '红包',
    cardName: this.$store.state.activeInfo.dzoeckrAlais,
    receiveAmount: this.receiveAmount,
    receiveDzoeckrNum: this.receiveDzoeckrNum,
    inviteUserNum: this.inviteUserNum,
    day: this.day,
    hours: this.hours,
    minutes: this.minutes,
    inviterCode: this.inviterCode,
    isInviter: this.isInviter
  }
}

export const pasteConfig = function pasteConfig() {
  return {
    bindCodeShow: this.bindCodeShow,
    pasteCode: this.pasteCode
  }
}

export const tipsConfig = function tipsConfig() {
  return {
    title: this.$store.state.activeInfo.dzoeckrAlais,
    page: 'active',
    showKey: 'tipsShow',
    customPage: (h) => {
      return (
        <div class="codeTips">
          <span>参与“每日抽奖”活动，数量越多，中奖几率越大！</span>
        </div>
      )
    }
  }
}

export const bindConfig = function bindConfig() {
  return {
    title: '',
    page: 'active',
    showKey: 'bindShow',
    customPage: (h) => {
      return (
        <div class="bind">
          <p class="bind-title">请先绑定微信</p>
          <img class="bind-btn" onClick={this.pushRoute} src={require('@/assets/img/icon/bind.png')}/>
          <p class="bind-tips">
            <p>*温馨提醒</p>
            <p>微信只能绑定一次，绑定后无法切换其他角色绑定</p>
          </p>
        </div>
      )
    }
  }
}

export const codeTipsConfig = function codeTipsConfig() {
  return {
    title: '邀请码绑定',
    page: 'reward',
    showKey: 'codeTipsShow',
    customPage: (h) => {
      return (
        <div class="codeTips">
          <p>1、请填写邀请码，否则您不纳入对方成功邀请范围；</p>
          <p>2、成功填写邀请码，即可获得游戏礼包一份，前往“我的礼包码”查看；</p>
          <p>3、一个角色只能绑定一个邀请码，绑定后无法更改。</p>
        </div>
      )
    }
  }
}