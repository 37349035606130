<template>
  <div>
    <div :style="bodyContainer">
      <Header 
        :config="headerConfig" 
        @handleShow="showTips"
        @handleCodeShow="showCodeTips"
        @handleShare="share"
        @handleBind="bindCode"
        @handleChange="inputChange"
        @handleFocus="inputFocus"
      ></Header>

      <div class="content">
        <div v-if="taskState.listShow" class="act-list">

          <van-row v-for="item in roleTaskDtoList"  :key="item.priority" class="act-list-item">

            <van-col span="8" class="task-rank">
              <div><img :src="item.taskRankImg"/></div>
              <div>
                <p>{{item.taskName}}</p>
              </div>
            </van-col>

            <van-col span="8" class="task-price-img">
              <img :src="item.priceIntroductionUrl"/>
            </van-col>

            <van-col span="8" class="task-price-button">
              <p>{{item.priceIntroduction}}</p>
              <div>
                <img @click="() => handleTask(item)" :src="item.taskStateImg"/>
              </div>
            </van-col>

          </van-row>

        </div>
        
        <div v-else>
          <img class="taskImg" :src="taskState.img"/>
        </div>
        
        
        <Explain :options="activityDescription"></Explain>
      </div>
    </div>
    

    <!-- 分享弹窗 -->
    <Overlay
      :isShow="shareShow"
      :config="shareConfig"
      @close="handleClose"
    ></Overlay>

    <!-- 提示弹窗 -->
    <Overlay
      :isShow="tipsShow"
      :config="tipsConfig"
      @close="handleClose"
    ></Overlay>

    <!-- 邀请码提示弹窗 -->
    <Overlay
      :isShow="codeTipsShow"
      :config="codeTipsConfig"
      @close="handleClose"
    ></Overlay>

    <!-- 未绑定微信弹窗 -->
    <Overlay
      :isShow="bindShow"
      :config="bindConfig"
      @close="handleClose"
    ></Overlay>

    <!-- loading 遮罩层 -->
    <Loading
      :isShow="loadingShow"
    ></Loading>

    <!-- 邀请码绑定弹窗 -->
    <BindCode
      :config="pasteConfig"
      @handleClose="closeCode"
      @handleChange="inputChange"
      @handleBind="bindCode"
    ></BindCode>
  </div>
</template>

<script>

import { config } from '@/config.js'
import { cd } from '@/utils/index.js'
import Header from '@/components/Header.vue'
import Explain from '@/components/Explain.vue'
import Overlay from '@/components/Overlay.vue'
import Qrcode from '@/components/Qrcode.vue'
import Loading from '@/components/Loading.vue'
import BindCode from '@/components/BindCode.vue'
import { reduce, startsWith } from 'lodash'
import baseMixin from '@/mixins/baseMixin.js'
import { shareConfig, headerConfig, tipsConfig, bindConfig, codeTipsConfig, pasteConfig } from './config.js'

require('@/utils/bridge.js')

export default {
  name: 'Active',
  mixins: [baseMixin],
  components: {
    Header,
    Explain,
    Overlay,
    Qrcode,
    Loading,
    BindCode
  },
  data () {
    return {
      Timer: undefined,
      leftTime: 0,
      day: '00',
      hours: '00',
      minutes: '00',
      roleTaskDtoList: [],
      inviteUserNum: 0,
      activityDescription: [],
      shareShow: false,
      receiveAmount: 0,
      receiveDzoeckrNum: 0,
      tipsShow: false,
      bindShow: false,
      loadingShow: false,
      codeTipsShow: false,
      banner: '',
      shareCode: {},
      moreInviteUrl: '',
      taskEndPicture: '',
      allTaskFinishState: false,
      inviterCode: '',
      isInviter: false,
      bindCodeShow: false,
      pasteCode: ''
    }
  },
  computed: {
    shareConfig,
    headerConfig,
    tipsConfig,
    bindConfig,
    codeTipsConfig,
    pasteConfig,
    taskState() {
      let listShow = true
      let img = ''
      if(this.leftTime < 0) {
        // 已超时
        listShow = false
        img = this.taskEndPicture
      } else if(this.allTaskFinishState) {
        // 任务已完成
        listShow = false
        img = this.moreInviteUrl
      }
      return {
        listShow,
        img
      }
    },
    bodyContainer() {
      const style = this.bodyStyle(['shareShow', 'tipsShow', 'codeTipsShow', 'bindShow'])
      return style
    }
  }, 
  beforeDestroy () {
    clearInterval(this.Timer)
  },
  mounted () {
    // 获取页面相关信息，任务列表
    this.getConfig()
    // 页面倒计时
    this.Timer = setInterval(this.countDown, 1000 * 60)
    // 打开页面打点
    this.logger('open_page',{
      page: 'asdk_task',
      behavior: 'jinli_open_task_page',
      behavior_name: '打开任务活动页面',
    })
  },
  methods: {
    closeCode() {
      this.bindCodeShow = false
      this.$store.commit('COVER_UPDATA', {name: 'bindOpenFlag', data: false})
    },
    formatList(List) {
      return reduce(List, (result, item) => {
        const { priority, state, inviteFlag } = item
        let customState = state
        if(state == 2 && inviteFlag) {
          customState = 6
        }
        const obj = Object.assign(item, {
          taskRankImg: require(`@/assets/img/active/task-${priority}.png`),
          taskStateImg: require(`@/assets/img/active/state-${customState}.png`),
          customState
        })
        result.push(obj)
        return result
      }, [])
    },
    getConfig() {
      this.loadingShow = true
      this.$ajax.curl({
        url: config.baseURL + '/o/operate/task/getTaskActivityInfo',
        method: 'get',
        userInfo: this.$store.state.userInfo
      }).then(res => {
        if(!res.success) {
          this.$toast(res.message)
          this.loadingShow = false
          return
        }
        const { data: {inviterCode, leftTime, roleTaskDtoList, activityDescription, inviteUserNum, receiveAmount, receiveDzoeckrNum, bannerUrl, taskEndPicture, allTaskFinishState, moreInviteUrl} } = res

        this.roleTaskDtoList = this.formatList(roleTaskDtoList || [])
        this.activityDescription = activityDescription.split('&#')
        this.receiveAmount = receiveAmount ? receiveAmount / 100 : 0
        this.receiveDzoeckrNum = receiveDzoeckrNum
        this.inviteUserNum = inviteUserNum
        this.leftTime = Number(leftTime) >= 0 ? leftTime : 0
        this.banner = bannerUrl
        this.moreInviteUrl = moreInviteUrl
        this.taskEndPicture = taskEndPicture
        this.allTaskFinishState = allTaskFinishState
        this.inviterCode = inviterCode
        this.isInviter = inviterCode ? true : false
        //初始化倒计时
        this.countDown()
        //判断是否应该弹出绑定邀请码弹窗
        this.handleBindShow()
        this.loadingShow = false
      })
    },
    async handleBindShow() {
      if(!this.isInviter) {
        WebViewJavascriptBridge.callHandler('getClipboardContent', {}, async (data) => {
          const { inviteContent } = data
          let content = inviteContent.split('?inviteCode=').length == 1 ? inviteContent : inviteContent.split('?inviteCode=')[1]
          const checkResult = await this.checkPaste(content)
          // bindOpenFlag: 当前环境用户关闭一次，不在弹出 
          if(checkResult && this.$store.state.bindOpenFlag) {
            this.pasteCode = content
            this.bindCodeShow = true
          }
        })
      }

      // const checkResult = await this.checkPaste('2LWEFCETM9')
      // if(checkResult && this.$store.state.bindOpenFlag) {
      //   this.pasteCode = '2LWEFCETMH'
      //   this.bindCodeShow = true
      // }
    },
    async checkPaste(content) {
      if(content == '') return false
      if((startsWith(content, '2L') && content.length == 10) || (startsWith(content, 'XF') && content.length == 11)) {
        await this.getShareConfig()
        const { inviteCode } = this.shareCode
        // 当剪切板内容与自身邀请码相同时，不需要弹出来
        if(content == inviteCode) {
          return false
        } else {
          return true
        }
      }
      return false
    },
    async share() {
      await this.getShareConfig()
      this.shareShow = true
    },
    showTips() {
      this.tipsShow = true
    },
    showCodeTips() {
      this.codeTipsShow = true
    },
    openApp(type) {
      const { shareContent, inviteCode } = this.shareCode
      const map = {
        'qq': 1,
        'wechat': 2
      }
      // 拉起分享打点
      this.logger('share_app', {
        page: 'asdk_share',
        target_platform: type,
        title: shareContent,
        invitation_code: inviteCode
      })
      WebViewJavascriptBridge.callHandler('openThirdApp', {
        appType: map[type],
        needCopy: true,
        content: shareContent,
        customUrlScheme: ""
      }, (data) => {
        console.log('跳转app----------', data)
      })
    },
    handleTask(item) {
      const { customState } = item
      const toastText = {
        2: '加油完成任务喔',
        3: '请先解锁任务内容',
        4: '创角7天内没完成任务，已无法再完成',
      }
      if(toastText[customState]){
        this.$toast(toastText[customState])
      } else if(customState === 1) {
        this.receiveTask(item)
      } else if(customState === 6) {
        this.share()
      }
    },
    async receiveTask(task) {
      const { taskActivityDetailId, taskName } = task
      let result = null
      let fail_detail = ''
      const res = await this.commonRequest('/o/operate/task/receive', 'post', {
        taskActivityDetailId
      })
      const { success, message, status } = res
      if(success) {
        // 实时更新”我的奖励“导航栏小红点
        this.$ajax.curl({
          url: config.baseURL + '/o/operate/activity/getOperatingActivityInfo',
          method: 'post',
          userInfo: this.$store.state.userInfo
        }).then(res => {
          const { success, message, data } = res
          if(success) {
            const { newAwardRecordNum } = data
            this.$store.commit('COVER_UPDATA', {name: 'rewardNum', data: newAwardRecordNum})
          } else {
            this.$toast(message)
          }
        })
        // 更新兑换码数量
        this.$ajax.curl({
          url: config.baseURL + '/o/operate/task/getTaskActivityInfo',
          method: 'get',
          userInfo: this.$store.state.userInfo
        }).then(res => {
          const {data: { receiveDzoeckrNum, roleTaskDtoList }} = res
          this.receiveDzoeckrNum = receiveDzoeckrNum
          this.roleTaskDtoList = this.formatList(roleTaskDtoList || [])
        })
        result = 1
      } else {
        if(status === 201) {
          this.bindShow = true
          return
        }
        result = 0
        fail_detail = message
      }

      this.logger('complete_task', {
        page: 'asdk_task',
        task_name: taskName,
        task_id: taskActivityDetailId.toString(),
        result,
        fail_detail
      })
    },
    pushRoute() {
      this.$router.push({path: '/reward'})
    },
    async getShareConfig() {
      const res = await this.commonRequest('/o/operate/task/share/getInviteCode')
      if(res.success) this.shareCode = res.data
    },
    async bindCode(key){
      if(this[key] === ''){
        this.$toast('请输入邀请码')
        return false
      }
      let result = null
      let fail_detail = ''
      const res = await this.commonRequest('/o/operate/task/share/bindInvite', 'post', {
        inviteCode: this[key]
      })
      const { success, message } = res
      if(success) {
        this.$toast('绑定成功')
        this.isInviter = true
        this.bindCodeShow = false
        result = 1
      } else {
        result = 0
        fail_detail = message
      }
      this.logger('bind_invitation_code', {
        page: 'asdk_share',
        invitation_code: this[key],
        result,
        fail_detail
      })
    },
    inputChange(code, key) {
      this[key] = code
    },
    inputFocus() {
      // console.log('focus')
      // WebViewJavascriptBridge.callHandler('getClipboardContent', {}, (data) => {
      //   const { inviteContent } = data
      //   alert(inviteContent)
      //   let code = inviteContent.split('###')[1]
      // })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/index.scss';

  .content{
    margin: px2rem(10px) px2rem(10px) 0;
    padding-bottom: px2rem(10px);
    // height: px2rem(420px);
    // overflow: overlay;

    .taskImg{
      width: 100%;
    }

    .act-list{
      margin-bottom: px2rem(15px);
      .act-list-item{
        padding: px2rem(6px);
        display: flex;
        align-items: center;

        background-image: url('../../assets/img/active/act-item-bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .task-rank{
          display: flex;
          align-content: center;
          align-items: center;
          img{
            width: px2rem(30px);
            margin-right: 4px;
          }
          p{
            font-family: FangZheng-ZhunYuan;
            @include font-dpr(14px);
            color: #fff;
            text-align: left;
          }
        }

        .task-price-img{
          img {
            width: 5.3125rem;
          }
        }

        .task-price-button{
          p{
            font-family: FangZheng-ZhunYuan;
            @include font-dpr(12px);
            color: #fff;
            margin-bottom: 4px;
          }
          img{
            width: px2rem(78.5px);
          }
        }
      }
    }

    .act-explain{
      img{
        width: 100%;
      }
      .act-explain-text{
        .text-item{
          display: flex;
          align-items: flex-start;
          align-items: center;
          img{
            width: px2rem(4px);
          }
          p{
            text-align: left;
            font-family: FangZheng-ZhunYuan;
            @include font-dpr(12px);
            margin: 0 0 px2rem(2px) px2rem(4px);
            color: #a25b7e;
          }
        }
      }
    }
  }
</style>

<style lang="scss">
@import '@/assets/css/index.scss';

// 分享弹窗
.share{
  padding: px2rem(30px) 0;
  .invite-code{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: px2rem(10px);
    p{
      font-family: FangZheng-ZhunYuan;
      @include font-dpr(14px);
      color: #9d5d7a;
      margin-right: px2rem(10px);
    }
    img{
      width: px2rem(61px);
    }
  }
  .QR-code{
    margin-bottom: px2rem(10px);
  }
  .share-icon{
    img{
      width: px2rem(32px);
      &:not(:last-child) {
        margin-right: px2rem(14px);
      }
    }
  }
}

// 绑定微信弹窗样式
.bind{
  font-family: FangZheng-ZhunYuan;
  .bind-title{
    color: #9d5d7a;
    @include font-dpr(17px);
    margin: px2rem(31px) 0 px2rem(18px);
  }
  .bind-btn{
    width: px2rem(100px);
    margin-bottom: px2rem(25px);
  }
  .bind-tips{
    @include font-dpr(9px);
    text-align: left;
    color: #dc7632;
    margin: 0 px2rem(40px) px2rem(50px);
  }
}

// 邀请码提示语
.codeTips{
  font-family: FangZheng-ZhunYuan;
  @include font-dpr(17px);
  color: #9d5b7a;
  text-align: left;
  padding: px2rem(25px) px2rem(25px) px2rem(50px);
  p{
    &:not(:last-child){
      margin-bottom: px2rem(30px);
    }
  }
}

// toast样式覆盖
.van-toast{
  background-color: rgba($color: #b7433c, $alpha: 0.8);
}

.van-toast__text{
  font-family: ZCOOL-XiaoWei;
  @include font-dpr(16px);
}
</style>