<template>
  <div>
    <div :style="bodyContainer">
      <Header 
        :config="headerConfig"
      ></Header>

      <div class="content">
        <div class="reward-list">
          <div v-for="(item, index) in rewardList" :key="index" class="reward-list-item">
            <div class="item-left">
              <div><img :src="item.imgUrl"/></div>
              <div class="item-left-text">
                <p>{{item.title}}</p>
                <p>{{item.details}}<span @click="handleCash" class="cashout" v-show="item.id === 'redPacket' ">提现</span></p>
              </div>
            </div>
            <div v-if="item.num" class="item-num"></div>
            <div class="operate-btn" @click="() => change(item.id)">
              <img v-show="item.id === 'weixin' && !item.flag" src="@/assets/img/active/bind-btn.png"/>
              <img v-show="item.id !== 'weixin' " src="@/assets/img/active/details-btn.png"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    

    <!-- 绑定微信 -->
    <Overlay
      :isShow="weixinShow"
      :config="weixinConfig"
      @close="handleClose"
    ></Overlay>

    <!-- 红包明细 -->
    <Overlay
      :isShow="redPtShow"
      :config="redPtConfig"
      @close="handleClose"
    ></Overlay>

    <!-- 礼包码明细 -->
    <Overlay
      :isShow="giftCodeShow"
      :config="giftCodeConfig"
      @close="handleClose"
    ></Overlay>

    <!-- 抽奖码明细 -->
    <Overlay
      :isShow="drawCodeShow"
      :config="drawCodeConfig"
      @close="handleClose"
    ></Overlay>

    <!-- 实物奖励明细 -->
    <Overlay
      :isShow="rewardShow"
      :config="rewardConfig"
      @close="handleClose"
    ></Overlay>

    <!-- 填写收货地址 -->
    <Overlay
      :isShow="addressShow"
      :config="addressConfig"
      @close="handleClose"
    ></Overlay>

    <!-- 提现 -->
    <Overlay
      :isShow="withdrawShow"
      :config="withdrawConfig"
      @close="handleClose"
    ></Overlay>

    <!-- loading 遮罩层 -->
    <Loading
      :isShow="loadingShow"
    ></Loading>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Explain from '@/components/Explain.vue'
import Overlay from '@/components/Overlay.vue'
import Loading from '@/components/Loading.vue'
import baseMixin from '@/mixins/baseMixin.js'

import { upperFirst } from 'lodash'
import { headerConfig, redPtConfig, giftCodeConfig, drawCodeConfig, rewardConfig, addressConfig, withdrawConfig, weixinConfig } from './config.js'
import { config } from '../../config.js'
import { reduce } from 'lodash'

require('@/utils/bridge.js')

export default {
  name: 'Reward',
  mixins: [baseMixin],
  components: {
    Header,
    Explain,
    Overlay,
    Loading
  },
  data () {
    return {
      Timer: undefined,
      leftTime: 0,
      day: '00',
      hours: '00',
      minutes: '00',
      pageConfig: {},
      redPtShow: false,
      giftCodeShow: false,
      drawCodeShow: false,
      rewardShow: false,
      addressShow: false,
      withdrawShow: false,
      weixinShow: false,
      loadingShow: false,
      redPtList: [],
      giftCodeList: [],
      drawCodeList: [],
      rewardData: [],
      user:{
        address: '',
        phone: '',
        receiver: ''
      },
      withdrawAmount: 0,
      drawOpt: [],
      weixinBind: {
        appCode: '',
        appName: '',
        bindCode: ''
      },
      withdrawSweetTip: '',
      withdrawRemark: '',
    }
  },
  computed: {
    redPtConfig,
    giftCodeConfig,
    drawCodeConfig,
    rewardConfig,
    addressConfig,
    withdrawConfig,
    weixinConfig,
    headerConfig,
    rewardList() {
      const { newRceiveDzoeckrNum, newReceiveGiftCodeNum, newReceivePriceItemNum, newRoleAmountNum, wechatBindFlag, wechatNickname, roleAmount, receiveDzoeckrNum, receiveGiftCodeNum, receivePriceItemNum, wechatAvatar } = this.pageConfig
      return [
        {
          title: '我的微信',
          imgUrl: wechatAvatar ? wechatAvatar : 'https://img01.ld.koolu.cn/images/game/2020_11_15/1608015640052/weixin.png',
          details: wechatBindFlag ? wechatNickname : '未绑定',
          id: 'weixin',
          flag: wechatBindFlag,
          num: 0
        },
        {
          title: '我的红包',
          imgUrl: require('@/assets/img/icon/redpacket.png'),
          details:  roleAmount ? `¥ ${roleAmount/100}元` : '¥ 0元',
          id: 'redPacket',
          num: newRoleAmountNum || 0
        },
        {
          title: `我的${this.$store.state.activeInfo.dzoeckrAlais}`,
          imgUrl:  this.$store.state.activeInfo.dzoeckrPicture || 'https://img01.ld.koolu.cn/images/game/2020_11_15/1608015640052/weixin.png',
          details: `数量：${receiveDzoeckrNum}张`,
          id: 'drawCode',
          num: newRceiveDzoeckrNum || 0,
        },
        {
          title: '我的礼包码',
          imgUrl: require('@/assets/img/icon/giftPackge.png'),
          details: `数量：${receiveGiftCodeNum}`,
          id: 'giftPackge',
          num: newReceiveGiftCodeNum || 0
        },
        {
          title: '我的实物奖励',
          imgUrl: require('@/assets/img/icon/reward.png'),
          details: `数量：${receivePriceItemNum}`,
          id: 'reward',
          num: newReceivePriceItemNum || 0
        }
      ]
    },
    bodyContainer() {
      const style = this.bodyStyle(['weixinShow', 'redPtShow', 'giftCodeShow', 'drawCodeShow', 'rewardShow', 'addressShow', 'withdrawShow'])
      return style
    }
  },
  beforeDestroy () {
    clearInterval(this.Timer)
  },
  mounted () {
    // 获取页面基础信息
    this.getConfig()
    // 页面倒计时
    this.Timer = setInterval(this.countDown, 1000 * 60)
    this.logger('open_page',{
      page: 'asdk_self',
      behavior: 'jinli_open_self_page',
      behavior_name: '打开个人奖励页面',
    })
    // 获取提现档位配置
    this.getDrawConfig()
  },
  methods: {
    change(id) { 
      this[`handle${upperFirst(id)}`](id)
    },
    async handleWeixin() {
      await this.getBindCode()
      this.weixinShow = true
    },
    bind() {
      if(!this.$store.state.version){
        this.$toast('此版本不支持截图功能，请手动截图并按照上述步骤进行绑定')
      } else {
        WebViewJavascriptBridge.callHandler('screenshotView', {}, (data) => {
          console.log('----------截图结果', data)
          const { resultCode } = data
          if(resultCode === 0) {
            this.$toast('截图失败，请手动截图并按照上述步骤进行绑定')
          }
        })
      }
    },
    async getDrawConfig() {
      const res = await this.commonRequest('/o/operate/award/getWithdrawConfig')
      const { gearList, withdrawSweetTip, withdrawRemark } = res.data
      this.drawOpt = reduce(gearList, (result, item) => {
        result.push({
          value: item,
          label: `${item / 100}元`
        })
        return result
      }, [])
      this.withdrawSweetTip = withdrawSweetTip
      this.withdrawRemark = withdrawRemark
    },
    refresh(id){
      const map = {
        'redPacket': 'newRoleAmountNum',
        'giftPackge': 'newReceiveGiftCodeNum',
        'drawCode': 'newRceiveDzoeckrNum',
        'reward': 'newReceivePriceItemNum'
      }
      const newNum = this.$store.state.rewardNum - this.pageConfig[`${map[id]}`]
      this.$store.commit('COVER_UPDATA', {name: 'rewardNum', data: newNum})
      this.pageConfig[`${map[id]}`] = 0
    },
    async handleRedPacket(id) {
      const res = await this.commonRequest('/o/operate/award/getRoleAmountAwards')
      this.redPtList = res.data.roleAmountAwardDtos
      this.redPtShow = true
      this.refresh(id)
    },
    async handleGiftPackge(id) {
      const res = await this.commonRequest('/o/operate/award/getRoleGiftCodeAwards')
      this.giftCodeList = res.data
      this.giftCodeShow = true
      this.refresh(id)
    },
    async handleDrawCode(id) {
      const res = await this.commonRequest('/o/operate/award/getRoleDzoeckrAwards')
      this.drawCodeList = res.data
      this.drawCodeShow = true
      this.refresh(id)
    },
    async handleReward(id) {
      const res = await this.commonRequest('/o/operate/award/getRolePrizeItemAwards')
      this.rewardData = res.data
      this.rewardShow = true
      this.refresh(id)
    },
    handleCash() {
      this.withdrawShow = true
    },
    async getConfig() {
      this.loadingShow = true
      const res = await this.commonRequest('/o/operate/award/getRoleAwardInfo')
      if(!res.success) {
        this.loadingShow = false
        return
      }
      this.pageConfig = res.data
      const {data: {inviterCode,operatingActivityLeftTime}} = res
      this.leftTime = Number(operatingActivityLeftTime) >= 0 ? operatingActivityLeftTime : 0
      this.isInviter = inviterCode ? true : false
      //初始化倒计时
      this.countDown()
      this.loadingShow = false
    },
    async getBindCode() {
      const res = await this.commonRequest('/o/operate/wechat/getBindCode')
      this.weixinBind = res.data
    },
    filterBg(rank) {
      return `draw-${rank}`
    },
    async goAddress() {
      //获取收货地址
      const res = await this.commonRequest('/o/operate/award/getUserLogisticsAddress')
      this.user = res.data
      //关闭实物大奖弹窗
      this.rewardShow = false
      //打开填写地址弹窗
      this.addressShow = true
    },
    async setAddress() {
      this.$ajax.curl({
        url: config.baseURL + '/o/operate/award/updateUserLogisticsAddress',
        method: 'post',
        userInfo: this.$store.state.userInfo,
        data: this.user
      }).then(res => {
        this.$toast('地址已修改')
        this.addressShow = false
      })
    },
    handleInput(val, item) {
      const { key } = item
      this.user[key] = val
    },
    clickRadio(e, val) {
      this.withdrawAmount = val
    },
    async withDraw() {
      if(!this.withdrawAmount) {
        this.$toast('请选择兑换金额')
        return false
      } else if(!this.pageConfig.wechatBindFlag) {
        this.$toast('请先绑定微信')
        return false
      } else if(this.withdrawAmount > this.pageConfig.roleAmount) {
        this.$toast('提现金额不足，继续做任务吧')
        return false
      }
      let result = 0
      let withdraw_id = ''
      let fail_detail = ''
      const res = await this.commonRequest('/o/operate/award/withdraw', 'post', {
        withdrawAmount: this.withdrawAmount
      })
      const { status, success, message } = res
      if(success){
        this.$toast('提现成功')
        result = 1
        withdraw_id = res.data.withdrawId
      } else{
        this.$toast(message)
        result = 0
        fail_detail = message
      }
      // 提现打点
      this.logger('withdraw_redpacked', {
        page: 'asdk_self',
        amount: this.withdrawAmount || '',
        withdraw_id,
        balance: this.pageConfig.roleAmount || '',
        result,
        fail_detail
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/index.scss';

.content{
  margin: px2rem(10px) px2rem(10px);

  .reward-list{
    .reward-list-item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: px2rem(18px) px2rem(9px) px2rem(10px) px2rem(9px);
      background: url('../../assets/img/active/reward-item-bg.png');
      background-repeat: no-repeat;
      background-size:  100% 100%;
      margin-bottom: px2rem(10px);
      position: relative;

      .item-num{
        position: absolute;
        top: px2rem(6px);
        right: px2rem(6px);
        width: 8px;
        height: 8px;
        background-color: red;
        border-radius: 100%;
      }

      .item-left{
        display: flex;
        align-items: center;

        img{
          width: px2rem(42.5px);
        }

        .item-left-text{
          text-align: left;
          margin-left: px2rem(10px);

          p{
            color: #9d5b7a;
            font-family: FangZheng-ZhunYuan;
            &:nth-child(1){
              @include font-dpr(17px);
              margin-bottom: px2rem(8px);
            }
            &:nth-child(2){
              @include font-dpr(12px);
            }
          }

          .cashout{
            font-family: FangZheng-ZhunYuan;
            // @include font-dpr(14px);
            width: px2rem(36px);
            height: px2rem(15px);
            line-height: px2rem(14px);
            background-color: #9d5b7a;
            color: #fff;
            padding: px2rem(2px) px2rem(10px);
            border-radius: px2rem(20px);
            margin-left: px2rem(12px);
          }
        }
      }

      .operate-btn{
        img{
          width: px2rem(87px);
        }
      }
    }
  }
}

</style>

<style lang="scss">
@import '@/assets/css/index.scss';

.list{
  padding: px2rem(10px);
  max-height: calc(50vh - 40px);
  overflow-y: scroll;
  .list-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: FangZheng-ZhunYuan;
    color: #9d5b7a;
    padding: px2rem(4px) 0;
    border-bottom: 1px solid #f1b3c3;

    .item-left{
      text-align: left;
    }

    .reward-left{
      display: flex;
      align-items: center;
      img{
        width: px2rem(26px);
      }
    }

    .item-title{
      @include font-dpr(12px);
    }

    .item-date{
      @include font-dpr(10px);
    }

    .item-reward-title{
      @include font-dpr(17px);
    }

    .drawIcon{
      width: px2rem(51px);
      height: px2rem(40px);
      background-size: contain;
      font-family: Microsoft YaHei;
      @include font-dpr(6px);
      padding-top: px2rem(4px);
    }
  }

  .address-btn{
    margin: px2rem(20px) 0 px2rem(13px) 0;
    img{
      width: px2rem(113.5px);
    }
  }
}

.copy-icon{
  width: px2rem(61px);
}

.van-toast{
  background-color: rgba($color: #b7433c, $alpha: 0.8);
}

.van-toast__text{
  font-family: ZCOOL-XiaoWei;
  @include font-dpr(16px);
}

// 填写收货地址
.address{
  padding: px2rem(12px) px2rem(31px) ;
  .address-input{
    margin-bottom: px2rem(17px);
    .item{
      display: flex;
      align-items: center;
      &:not(:last-child){
        margin-bottom: px2rem(5px);
      }
      .item-label{
        text-align: right;
        padding-right: px2rem(5px);
        font-family: FangZheng-ZhunYuan;
        @include font-dpr(14px);
        color: #9d5b7a;
      }

      .van-cell{
        background-color: transparent;
        border: 0.5px solid #E4DCE2;
        border-radius: px2rem(4px);
      }

      .van-field__control{
        text-indent: 4px;
        font-family: FangZheng-ZhunYuan;
        @include font-dpr(10px);
        // color: #d9d9d9;
      }
    }
  }

  .confirm-btn{
    margin-bottom: px2rem(6px);
    img{
      width: px2rem(76px);
    }
  }

  .promise-text{
    text-align: left;
    font-family: FangZheng-ZhunYuan;
    color: #dc7632;
    @include font-dpr(10px);
  }
}

// 兑换金额
.withDraw{
  padding: px2rem(15px) px2rem(35px);
  .radio-group{
    display: flex;
    flex-wrap: wrap;
    .redio-item{
      width: 50%;
      padding-left: 12%;
      padding-bottom: px2rem(10px);
    }
  }
  .tips{
    margin: 0 0 px2rem(10px);
    text-align: left;
    font-family: FangZheng-ZhunYuan;
    @include font-dpr(9px);
    color: #9d5b7a;
    p{
      &:nth-child(3){
        color: #dc7632;
      }
    }
  }
  .btn{
    img{
      width: px2rem(77px);
    }
  }
}

// 绑定微信
.weixin{
  padding: px2rem(15px) px2rem(30px);
  p{
    text-align: left;
    font-family: FangZheng-ZhunYuan;
    @include font-dpr(9px);
    color: #9d5b7a;
    &:last-child{
      color: #dc7632;
    }
    &:not(:last-child){
      margin-bottom: px2rem(12px);
    }
  }
  img{
    width: px2rem(100px);
    margin-bottom: px2rem(12px);
  }
}

.weixin-bind{
  padding: px2rem(15px) px2rem(30px);
  p{
    color: #9d5b7a;
    font-family: Microsoft YaHei;
    @include font-dpr(15px);
    text-align: left;
  }
  .title{
    text-align: center;
    margin: px2rem(20px) 0 px2rem(10px);
  }
  .step{
    @include font-dpr(14px);
    .col-span{
      color: #ff8100;
    }
  }

  .button{
    margin-top: px2rem(30px);
    background-color: #ff9e3b;
    border-radius: px2rem(4px);
    padding: px2rem(10px);
    p{
      color: #ffffff;
      font-family: Microsoft YaHei;
      @include font-dpr(16px);
      text-align: center;
    }
  }
}
</style>
