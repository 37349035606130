<template>
  <div>
    <div :style="bodyContainer">
      <Header :config="headerConfig"></Header>

      <div class="content">
        <div class="lottery-record">
          <div class="tips">
            <p>{{`*${this.$store.state.activeInfo.dzoeckrAlais}每日可重复中奖`}}</p>
            <img @click="getDes" src="@/assets/img/icon/tips-icon.png"/>
          </div>

          <div v-if="!endPicShow" class="table">
            <van-row class="table-header">
              <van-col class="header-item" v-for="(item, index) in tableData.headerTitle" :key="index" :span="item.span">{{item.lable}}</van-col>
            </van-row>

            <van-row class="table-list" v-for="(item, index) in tableData.winnersList" :key="index">
              <van-col span="6">{{item.prizeRank}}</van-col>
              <van-col span="9">{{item.publicTime}}</van-col>
              <van-col span="9">{{item.luckyDrawCode !== '' ? item.gameRoleName : '待开奖'}}</van-col>
            </van-row>

            <van-row class="table-button">
              <van-col span="24">
                <img @click="getRecord" src="@/assets/img/active/winner-btn.png" />
              </van-col>
            </van-row>
          </div>

          <div v-if="endPicShow" class="endPicShow">
            <img :src="endPicture"/>
            <img @click="getRecord" src="@/assets/img/active/winner-btn.png"/>
          </div>

        </div>
        
        <Explain :options="activityDescription"></Explain>

      </div>
    </div>
    
    <!-- 历史开奖记录 -->
    <Overlay
      :isShow="recordListShow" 
      :config="recordConfig"
      @close="handleClose"
    ></Overlay>

    <!-- 简述图片弹窗 -->
    <Overlay
      :isShow="drawPicShow"
      :config="drawPicConfig"
      @close="handleClose"
    ></Overlay>

    <!-- 抽奖开始倒计时弹窗 -->
    <CountDown
      :isShow="countDownShow"
      :config="countConfig"
    ></CountDown>

    <!-- loading 遮罩层 -->
    <Loading
      :isShow="loadingShow"
    ></Loading>
    
  </div>
</template>

<script>
import { config } from '@/config.js'
import { cd } from '@/utils/index.js'
import Header from '@/components/Header.vue'
import Explain from '@/components/Explain.vue'
import Overlay from '@/components/Overlay.vue'
import CountDown from '@/components/CountDown.vue'
import Loading from '@/components/Loading.vue'
import baseMixin from '@/mixins/baseMixin.js'
import { recordConfig, countConfig, headerConfig, drawPicConfig } from './config.js'

export default {
  name: 'Lottery',
  mixins: [baseMixin],
  components: {
    Header,
    Explain,
    Overlay,
    CountDown,
    Loading
  },
  data () {
    return {
      Timer: undefined,
      leftTime: 0,
      day: '00',
      hours: '00',
      minutes: '00',
      activityDescription: [],
      period: 1,
      periodOpt: [],
      tableData: {
        headerTitle: [
          {span: 6, lable: '奖励'},
          {span: 9, lable: '开奖时间'},
          {span: 9, lable: '获奖玩家'}
        ],
        winnersList: []
      },
      recordListShow: false,
      countDownShow: false,
      drawPicShow: false,
      loadingShow: false,
      recordList: [],
      receiveAmount: 0,
      receiveDzoeckrNum: 0,
      luckyDrawId: 0,
      bannerUrl: '',
      endPicShow: false,
      endPicture: ''
    }
  },
  computed: {
    recordConfig,
    countConfig,
    headerConfig,
    drawPicConfig,
    bodyContainer() {
      const style = this.bodyStyle(['recordListShow', 'drawPicShow', 'countDownShow'])
      return style
    }
  },
  beforeDestroy() {
    clearInterval(this.Timer)
  },
  mounted () {
    this.getConfig()
    this.countDown()
    this.Timer = setInterval(this.countDown, 1000 * 60)
    this.logger('open_page',{
      page: 'asdk_lucky',
      behavior: 'jinli_open_lucky_page',
      behavior_name: '打开抽奖活动页面',
    })
  },
  methods: {
    getConfig() {
      this.loadingShow = true
      this.$ajax.curl({
        url: config.baseURL + '/o/operate/luckydraw/info',
        method: 'get',
        userInfo: this.$store.state.userInfo
      }).then(res => {
        if(!res.success) {
          this.$toast(res.message)
          this.loadingShow = false
          return
        }
        
        const { success, message, data: {activityDescription,descriptionUrl, bannerUrl, endPicture, leftTime, fromStartTime, winnersList, receiveAmount, receiveDzoeckrNum, currentPeriod, periodHistory, luckyDrawId}} = res

        this.activityDescription = activityDescription.split('&#')
        if(fromStartTime > 0 && leftTime === 0) {
          // 活动未开始，出现倒计时弹窗
          this.leftTime = fromStartTime
          this.countDownShow = true
        } else if(fromStartTime === 0 && leftTime > 0) {
          // 活动进行中
          this.leftTime = leftTime
          this.endPicShow = false
        } else {
          // 活动已结束，出现已结束图片
          this.leftTime = leftTime
          this.endPicShow = true
          this.endPicture = endPicture
        }
        
        this.tableData.winnersList = winnersList && winnersList.slice(0, 10)
        this.receiveAmount = receiveAmount ? receiveAmount / 100 : 0 
        this.receiveDzoeckrNum = receiveDzoeckrNum
        this.luckyDrawId = luckyDrawId
        this.bannerUrl = bannerUrl
        this.descriptionUrl = descriptionUrl
        this.period = currentPeriod
        this.periodOpt = periodHistory.map(item => {
          return {
            text: `第${item}期`,
            value: item
          }
        })
        //初始化倒计时
        this.countDown()
        this.loadingShow = false
      })
    },
    async getRecord() {
      const res = await this.commonRequest('/o/operate/luckydraw/history', 'post', {
        luckyDrawId: this.luckyDrawId,
        period: this.period
      })
      const { success, message, data: {winnersList} } = res
      if(success) {
        this.recordList = winnersList
        this.recordListShow = true
      } else {
        this.$toast(message)
      }
    },
    handleClose(key) {
      this[key] = false
    },
    handleChange(val) {
      this.period = val
      this.getRecord()
    },
    getDes() {
      this.drawPicShow = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/index.scss';

.content{
  margin: px2rem(10px) px2rem(10px);

  .lottery-record {

    .tips {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-family: FangZheng-ZhunYuan;
        @include font-dpr(10px);
        color: #eb574e;
      }

      img {
        width: px2rem(12px);
      }
    }

    .table{
      border: 1px solid #f1b3c3;
      font-family: FangZheng-ZhunYuan;
      margin: px2rem(6px) 0 px2rem(20px);

      .table-header{
        background: linear-gradient(#f88ba7, #f1b9bf);
        @include font-dpr(15px);
        color: #fff;

        .header-item{
          &:not(:last-child){
            border-right: 1px solid #df8fa1;
          }
        }
      }

      .table-list{
        @include font-dpr(10px);
        color: #a25b7e;
        padding: px2rem(4px) 0;
        &:not(:last-child) {
          border-bottom: 1px solid #f1b3c3;
        }
      }

      .table-button{
        img{
          width: px2rem(211px);
        }
      }
    }

    .endPicShow{
      margin: px2rem(6px) 0 px2rem(40px);
      img{
        &:nth-child(1){
          width: 100%;
          margin-bottom: px2rem(10px);
        }
        &:nth-child(2){
          width: px2rem(211px);
        }
      }
    }
  }
}

</style>

<style lang="scss">
@import '@/assets/css/index.scss';

.record-list-wrapper{
  height: calc(50vh - 40px);
  overflow-y: scroll;
  // -webkit-overflow-scrolling : touch;
  .record-list{
    display: flex;
    align-items: center;
    @include font-dpr(9px);
    color: #9d5b7a;
    padding: px2rem(10px) 0;
    font-family: FangZheng-ZhunYuan;
    &:not(:last-child) {
      border-bottom: 1px solid #f1b3c3;
    }
  }

  .record-list-header{
    @include font-dpr(12px);
  }
}

.select{
  .van-dropdown-menu__bar{
    background-color: transparent;
    box-shadow: none;
    height: px2rem(40px);
  }
  .van-dropdown-item__content{
    width: 15%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .van-ellipsis{
    font-family: ZCOOL-XiaoWei;
    @include font-dpr(21px);
    color: #b7433c;
  }
  // 隐藏下拉框右侧打勾icon
  .van-cell__value{
    display: none;
  }
  .van-cell__title{
    text-align: center;
    color: #fff;
  }
  .van-cell{
    background-color: #b7433c;
    opacity: 0.8;
    font-family: FangZheng-ZhunYuan;
    @include font-dpr(10px);
    color: #a25b7e;
    padding: px2rem(4px) 0;
    &::after{
      display: none;
    }
  }
  .van-dropdown-item__option--active{
    background-color: #b7433c;
    opacity: 1;
  }
  .van-dropdown-menu__title{
    &::after{
      border: 6px solid;
      border-color: transparent transparent #fff #fff;
      top: 30%;
      right: -10px;
    }
  }
  .van-dropdown-menu__title--active{
    &::after{
      border-color: transparent transparent #b7433c #b7433c;
    }
  }
}

.description{
  margin: px2rem(15px) px2rem(5px);
  img{
    width: 100%;
  }
}
</style>