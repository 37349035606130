import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
    activeInfo: {},
    version: '',
    bindOpenFlag: true,
    rewardNum: 0
  },
  mutations: {
    COVER_UPDATA: (state, { name, data }) => {
      state[name] = data
    }
  },
  actions: {
  },
  modules: {
  }
})
