import axios from 'axios'

function getFetch (url, data) {
  return request({
    url,
    method: 'get',
    data
  })
}

function postFetch (url, data) {
  return request({
    url,
    method: 'post',
    data
  })
}

const request = function request (config) {
  let { data, method, userInfo } = config
  let newMethod = method
  if (!method) {
    newMethod = 'get'
  }
  if (!Array.isArray(data)) {
    data = Object.assign({}, data)
  }

  let params = {}
  if (newMethod.toLowerCase() === 'get') {
    params = data
  } else {
    config.data = data
  }
  
  return axios(Object.assign(config, {
    method: newMethod,
    params,
    headers: Object.assign({}, userInfo, {
      'Content-Type': 'application/json'
    })
  })).then(ret => {
    const res = ret.data
    return res
  }).catch(error => {
    throw new Error(error)
  })
}

request.curl = request
request.get = getFetch
request.post = postFetch
export default request