import { cd } from '@/utils/index.js'
import { config } from '../config.js'
import { reduce } from 'lodash'

export default {
  data() {
    return {}
  },
  methods: {
    countDown() {
      if (this.leftTime <= 0) clearInterval(this.Timer)
      this.day = cd(this.leftTime, 'd')
      this.hours = cd(this.leftTime, 'h')
      this.minutes = cd(this.leftTime, 'm')

      this.leftTime = this.leftTime - 1000 * 60
    },
    handleClose(key) {
      this[key] = false
      if(key === 'weixinShow') {
        this.getConfig()
      }
    },
    logger(action, config) {
      const { gameRoleId, gameServerId, operatingActivityId, gameId} = this.$store.state.userInfo
      const { gameRoleName, gameServerName, operatingActivityName} = this.$store.state.activeInfo
      const data = Object.assign(config, {
        project: 'jinli',
        project_name: '锦鲤抽奖活动',
        activity_id: operatingActivityId,
        activity_name: operatingActivityName,
        role_id: gameRoleId,
        role_name: gameRoleName,
        server_id: gameServerId,
        server_name: gameServerName,
        host: location.host,
        api: location.pathname,
        gameid: gameId
      })
      const logger = window.loggerFactory.getLogger('event')
      logger.setAction(action).put(data).send().then(data => {
        console.log('上报成功', data)
      }).catch(err => {
        console.log('上报失败', err)
      })
    },
    commonRequest(path, method='get', data={}, extra) {
      return this.$ajax.curl({
        url: config.baseURL + path,
        method,
        data,
        userInfo: this.$store.state.userInfo
      }).then(res => {
        console.log(res)
        const { success, message, status } = res
        if(!success && status !== 201) {
          this.$toast(message)
        }
        return res
      })
    },
    copyCode(val, toastText) {
      const aux = document.createElement("input")
      document.body.appendChild(aux)
      aux.setAttribute("value", val)
      aux.setAttribute("readonly", "readonly")
      aux.select()
      if(document.execCommand("copy")) {
        this.$toast(toastText)
      }
      document.body.removeChild(aux)
    },
    bodyStyle(showArr) {
      const resultArr = reduce(showArr, (result, item) => {
        result.push(this[item] ? 1 : 0)
        return result
      }, [])
      if(resultArr.length && resultArr.indexOf(1) !== -1){
        return {
          'position': 'fixed',
          'width': '100%'
        }
      } else {
        return {}
      }
    },
  }
}