<template>
  <div class="act-explain">
    <img class="explain-title" src="@/assets/img/active/explain-title.png"/>
    <div class="act-explain-text"> 
      <div class="text-item" v-for="(item, index) in options" :key="index">
        <img src="@/assets/img/active/explain-dot.png"/>
        <p>{{item}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Explain',
  props: {
    options: {
      type: Array,
      default: function() {
        return []
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/index.scss';

.act-explain{
  img{
    width: 100%;
  }

  .explain-title{
    margin-bottom: px2rem(17px);
  }

  .act-explain-text{
    .text-item{
      display: flex;
      align-items: flex-start;
      img{
        width: px2rem(4px);
        margin-top: px2rem(6px);
      }
      p{
        text-align: left;
        font-family: FangZheng-ZhunYuan;
        @include font-dpr(12px);
        margin: 0 0 px2rem(2px) px2rem(4px);
        color: #a25b7e;
      }
    }
  }
}
</style>