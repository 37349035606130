<template>
  <van-overlay :show="config.bindCodeShow">
    <div class="container">
      <div class="bindCode">
        <img @click="close" class="close absolute" src="../assets/img/active/bindCode-close.png"/>
        <div class="input absolute">
          <div class="codeInput">
            <van-field v-model="config.pasteCode" @input="inputChange"></van-field>
          </div>
          <p>完成绑定领礼包</p>
        </div>
        <div class="confirm absolute">
          <img @click="bind" src="../assets/img/active/bindCode-confirm.png"/>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  name: 'BindCode',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    inviterCode: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      default: function() {
        return {
          bindCodeShow: false,
          pasteCode: ''
        }
      }
    }
  },
  methods: {
    close() {
      this.$emit('handleClose')
    },
    bind() {
      this.$emit('handleBind', 'pasteCode')
    },
    inputChange(val) {
      this.$emit('handleChange', val, 'pasteCode')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/index.scss';

.van-overlay{
  .container{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: #150504, $alpha: 0.2);
    color: red;
    .bindCode{
      background-image: url('../assets/img/active/bindCode-bg.png');
      width: px2rem(302px);
      height: px2rem(276px);
      background-size: contain;
      position: relative;
      .absolute{
        position: absolute;
      }
      .close{
        width: px2rem(27.5px);
        right: 0;
        top: 0;
      }
      .input{
        top: px2rem(178px);
        width: 100%;
        p{
          font-family: Microsoft YaHei;
          @include font-dpr(17px);
          // color: #fff;
          color: #d76f82;
          margin-top: px2rem(4px);
        }
        .codeInput{
          display: flex;
          justify-content: center;
          .van-cell{
            width: px2rem(195px);
            height: px2rem(35px);
            border-radius: px2rem(8px);
            // border: 1px solid #ff7e51;
            border: 1px solid #d76f82;
          }
        }
      }
      
      .confirm{
        width: 100%;
        bottom: px2rem(-24px);
        left: 0;
        img{
          width: px2rem(187px);
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import '../assets/css/index.scss';
.codeInput{
  .van-field__body{
    height: 100% !important;
    // background-color: #b73516;
    background-color: #fbd9e0;
  }
  .van-field__control{
    font-family: Microsoft YaHei;
    @include font-dpr(17px);
    // color: #fff;
    color: #d76f82;
    text-align: center;
  }
}
</style>