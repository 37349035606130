<template>
  <div>
    <!-- <van-sticky> -->
      <div class="banner" :style="{ 'background': 'url(' + config.banner + ')' }">
        <div class="close">
          <img @click="handleClose" src="../assets/img/icon/close-icon.png"/>
        </div>
        <div v-if="config.page === 'active'" class="invite">

          <van-row class="banner-input">
            <van-col span="3"></van-col>
            <van-col span="18" class="input">
              <van-field v-model="config.inviterCode" @input="inputChange" @focus="inputFocus" placeholder="输入邀请码领取礼包"></van-field>
              <img v-if="config.isInviter" src="../assets/img/active/confirmed-btn.png"/>
              <img v-else @click="(e) => bindCode(config.inviterCode)" src="../assets/img/active/confirm-btn.png"/>
            </van-col>
            <van-col span="3" class="tips">
              <img @click="showCodeTips" src="../assets/img/icon/tips-icon-white.png"/>
            </van-col>
          </van-row>
          
        </div>
      </div>

      <div v-if="config.page === 'active'">
        <div class="active-invite">
          <img @click="share" class="invite-button" src="@/assets/img/active/invite-button.png"/>
          <p>{{`累计邀请好友：${config.inviteUserNum}人`}}</p>
        </div>
      </div>

      <div class="countDown">
        <div class="count-row">
          <div class="title">{{config.title}}</div>
          <div class="time" v-for="item in timeArr" :key="item.text">
            <div class="number">{{item[0]}}</div>
            <div class="number">{{item[1]}}</div>
            <div class="text">{{item.text}}</div>
          </div>
        </div>

        <div v-if="config.page === 'reward'" class="count-row count-row-pack">
          <div class="item-flex">
            <img class="icon" src="../assets/img/icon/account-icon.png"/>
            <p>{{`当前登入角色：${config.gameRoleId}`}}</p>
          </div>
        </div>

        <div v-else class="count-row count-row-pack">
          <div class="pack item-flex">
            <img class="icon" src="../assets/img/icon/pack-icon.png"/>
            <p>{{`获得${config.packName}： ${config.receiveAmount}元`}}</p>
          </div>
          <div class="card item-flex">
            <img class="icon" src="../assets/img/icon/card-icon.png"/>
            <p>{{`获得${config.cardName}： ${config.receiveDzoeckrNum}张`}}</p>
            <img v-if="config.page === 'active'" @click="showTips" class="tip-icon" src="../assets/img/icon/tips-icon.png"/>
          </div>
          
        </div>
      </div>
    <!-- </van-sticky> -->

    <!-- 二次确认关闭弹窗 -->
    <van-overlay :show="closeShow">
      <div class="container">
        <div class="wrapper">
          <img @click="close" src="../assets/img/icon/confirm-close.png"/>
          <img @click="cancel" src="../assets/img/icon/cancel.png"/>
        </div>
      </div>
    </van-overlay>
    
  </div>
</template>

<script>
require('@/utils/bridge.js')

import Overlay from './Overlay.vue'

var clickTag = 0

export default {
  name: 'Header',
  data() {
    return {
      closeShow: false
    }
  },
  components: {
    Overlay
  },
  props: {
    config: {
      type: Object,
      default: function () {
        return {
          title: '活动倒计时',
          banner: 'https://img01.ld.koolu.cn/images/game/2020_11_3/1607001731138/banner.png',
          day: '00',
          hours: '00',
          minutes: '00',
          packName: '红包',
          cardName: '少女卡',
          inviterCode: ''
        }
      }
    }
  },
  computed: {
    timeArr: function () {
      const { day, hours, minutes } = this.config
      const obj = {
        day,
        hours,
        minutes
      }
      return Object.keys(obj).map(key => {
        const text = key === 'day' ? '天' : ( key === 'hours' ? '时' : '分' )
        return {
          0: obj[key].toString().substr(0, 1),
          1: obj[key].toString().substr(1, 1),
          text: text
        }
      })
    }
  },
  mounted() {
  },
  methods: {
    handleClose() {
      // this.closeShow = true
      this.close()
    },
    close() {
      WebViewJavascriptBridge.callHandler('closeMainView', {}, (data) => {
        console.log('----------关闭成功', data)
      })
    },
    cancel() {
      this.closeShow = false
    },
    showTips() {
      this.$emit('handleShow')
    },
    showCodeTips() {
      this.$emit('handleCodeShow')
    },
    bindCode() {
      if(clickTag === 0) {
        clickTag = 1
        this.$emit('handleBind', 'inviterCode')
        setTimeout(() => { clickTag = 0}, 3000)
      }
    },
    inputChange(val) {
      this.$emit('handleChange', val, 'inviterCode')
    },
    inputFocus() {
      this.$emit('handleFocus')
    },
    share() {
      this.$emit('handleShare')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/index.scss';

.banner{
  width: 100%;
  height: px2rem(163px);
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  position: relative;

  .close{
    padding: px2rem(10px) px2rem(10px) 0 0;
    text-align: right;
    img{
      width: px2rem(17px);
    }
  }
  
  .invite{
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: px2rem(10px);

    .banner-title{
      margin-bottom: px2rem(4px);
      span{
        &:first-child{
          font-family: ZCOOL-XiaoWei;
          @include font-dpr(22px);
          letter-spacing: px2rem(-5px);
          color: #ec5950;
        }
        &:nth-child(2){
          @include font-dpr(9px);
          font-family: FangZheng-ZhunYuan;
          color: #ec5950;
        }
      }
    }

    .banner-input{
      display: flex;
      align-items: center;
      margin: 0 px2rem(10px);
      .input{
        display: flex;
        justify-content: center;
        align-items: center;
        .van-cell{
          width: px2rem(160px);
          height: px2rem(23px);
          align-items: center;
          border-radius: px2rem(6px);
          border: 1px solid #ec5950;
          margin-right: px2rem(4px);
        }
        img{
          width:  px2rem(78.5px);
        }
      }
      .tips{
        text-align: left;
        img{
          width: px2rem(20px);
        }
      }
      
    }
  }
}

.active-invite{
  // padding: px2rem(10px);
  width: 100%;
  .down{
    display: block;
    margin: 0 auto;
    width: px2rem(26.5px);
  }
  .invite-button{
    width: px2rem(211px);
    margin-top: px2rem(4px);
  }
  p{
    margin-top: px2rem(4px);
    @include font-dpr(14px);
    font-family: FangZheng-ZhunYuan;
    color: #a25b7e;
  }
}

.countDown{
  margin-top: px2rem(10px);
  .count-row{
    display: flex;
    justify-content: center;
  }

  .title{
    @include font-dpr(21px);
    font-family: ZCOOL-XiaoWei;
    color: #eb574e;
    letter-spacing: px2rem(-3px);
  }

  .time{
    display: flex;
    align-items: center;
    
    .number {
      background: url('../assets/img/icon/count-num-bg.png');
      width: px2rem(22px);
      height: px2rem(22px);
      @include font-dpr(21px);
      background-size: cover;
      font-family: ZCOOL-XiaoWei;
      color: #fff;
      margin-left: px2rem(2px);
    }

    .text{
      @include font-dpr(10px);
      color: #eb574e;
      font-family: FangZheng-ZhunYuan;
      margin-left: px2rem(2px);
    }
  }

  .count-row-pack{
    @include font-dpr(12px);
    font-family: FangZheng-ZhunYuan;
    color: #e8908a;
    padding-top: 5px;
  }

  .item-flex{
    display: flex;
    align-items: center;
    &:not(:last-child){
      margin-right: px2rem(12px);
    }
  }

  .icon{
    width: px2rem(13px);
    margin-right: px2rem(2px);
  }

  .tip-icon{
    width: px2rem(12px);
    margin-left: px2rem(4px);
  }

}

.van-overlay{
  z-index: 9;
  .container{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .wrapper{
      width: px2rem(283px);
      height: px2rem(130px);
      background: url('../assets/img/icon/close-bg.png');
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 px2rem(30px);
      img{
        width: px2rem(100px);
      }
    }
  }
}
</style>

<style lang="scss">
.input{
  .van-field__control{
    text-indent: 4px !important;
  }
}
</style>
