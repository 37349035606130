export function cd (leftTime, tg) {
  var minutes = 1000 * 60
  var hours = minutes * 60
  var days = hours * 24
  var years = days * 365
  var d = Math.floor(leftTime / days)
  leftTime %= days
  var h = Math.floor(leftTime / hours)
  leftTime %= hours
  var m = Math.floor(leftTime / minutes)
  leftTime %= minutes
  var s = Math.floor(leftTime / 1000)

  if (d < 10) d = '0' + d
  if (h < 10) h = '0' + h
  if (m < 10) m = '0' + m

  switch (tg) {
    case 'd': return d
    case 'h': return h
    case 'm': return m
    case 's': return s
  }
}
