const env = process.env.NODE_ENV || 'development'

console.log('---------process.env.NODE_ENV---------', process.env.NODE_ENV)

const prodConf = {
  baseURL: 'https://api.act.holdliang.com',
  report: {
    appId: 'selfsdk',
    appSecret: 'YGkssamewodsks3saj2',
    appV: '1.0.0',
    host: 'https://applog.dc.hoho666.com'
  },
  eagleReport: {
    appId: 'eaglesdk',
    appSecret: '450rsjsxa2nvOMxsw',
    appV: '1.0.0',
    host: 'https://applog.dc.hoho666.com'
  }
};

const devConf = {
  baseURL: 'http://api.act.test.xf.io',
  report: {
    appId: 'selfsdk',
    appSecret: 'YGkssamewodsks3saj2',
    appV: '1.0.0',
    host: 'https://applog.test.dc.hoho666.com'
  },
  eagleReport: {
    appId: 'eaglesdk',
    appSecret: '450rsjsxa2nvOMxsw',
    appV: '1.0.0',
    host: 'https://applog.test.dc.hoho666.com'
  }
}

export const config = env === 'production' ? prodConf : devConf
