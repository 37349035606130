<script>
export default {
  name: 'Overlay',
  props: {
    config: {
      type: Object,
      default: {
        customPage: (h) => {},
        title: '',
        page: '',
        showKey: ''
      }
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  render() {
    const { title, page, showKey } = this.config
    const { isShow } = this
    return (
      <van-overlay show={isShow} lockScroll={false}>
        <div class="container">
          <div class={showKey === 'weixinShow' ? 'wrapper bind-wrapper' : 'wrapper'}>
            <van-row class="wrapper-header">
              <van-col span="2"></van-col>
              {page === 'lottery' ? (
                this.customTitle()
              ) : (
                <van-col span="20" class="title">{title}</van-col>
              )}
              <van-col span="2" onClick={this.close}>
                <img src={require('../assets/img/icon/close-icon.png')}/>
              </van-col>
            </van-row>
            {
              this.customRender()
            }
          </div>
        </div>
        
      </van-overlay>
    )
  },
  methods: {
    customRender() {
      const { customPage } = this.config
      const h = this.$createElement
      return customPage(h)
    },
    customTitle() {
      const { customTitle } = this.config
      const h = this.$createElement
      return customTitle(h)
    },
    close() {
      this.$emit('close', this.config.showKey)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/index.scss';
.van-overlay{
  z-index: 9;
  .container{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .bind-wrapper{
      width: 80% !important;
      max-height: 90% !important;
    }
    .wrapper{
      width: px2rem(296px);
      background: linear-gradient(150deg,#f9e9e9, #f8f7fc);
      border: 1px solid #eca5b3;
      border-radius: 10px;
      min-height: 20%;
      max-height: 70%;

      .wrapper-header{
        background: url('../assets/img/active/popup-header-bg.png');
        padding: px2rem(5px);
        height: px2rem(54px);
        background-size: 100% 100%;
        // display: flex;
        // align-items: center;

        .title{
          font-family: ZCOOL-XiaoWei;
          @include font-dpr(21px);
          color: #ffffff;
          line-height: px2rem(36px);
        }

        img{
          width: px2rem(17px);
        }
      }
    }
  }
}



</style>