<template>
  <div id="app">
    <router-view v-if="isRouter"/>

    <van-tabbar route :placeholder="true" :border="false">
      <van-tabbar-item :dot="item.dot" replace v-for="item in tabs" :key="item.key" :name="item.key" :to="item.path">
        <template #icon="props">
          <img :src="props.active ? item.icon.active : item.icon.inactive"/>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { config } from './config';
require('./utils/bridge.js')

export default {
  data () {
    return {
      isRouter: false
    }
  },
  computed: {
    tabs() {
      const tabs = [
        { key: 'active', active: 'taskActiveButton', inactive: 'taskNormalButton' },
        { key: 'lottery', active: 'luckDrawActiveButton', inactive: 'luckDrawNormalButton' },
        { key: 'reward', active: 'awardActiveButton', inactive: 'awardNormalButton' },
      ]
      return tabs.map(item => {
        const { key, active, inactive } = item
        const dot = key === 'reward' ? ( this.$store.state.rewardNum > 0 ? true : false ) : false
        return {
          key,
          path: `/${key}`,
          dot,
          icon: {
            active: this.$store.state.activeInfo[active],
            inactive: this.$store.state.activeInfo[inactive]
          }
        }
      })
    }
  },
  created() {
    console.log('-------config.baseURL--------', config.baseURL);
    // 获取初始化信息
    this.initInfo()
  },
  methods: {
    initInfo() {
      console.log('---------开始获取初始化信息----------')
      const bridge = window.WebViewJavascriptBridge
      bridge.callHandler('getInitInfo', {}, (data) => {
        console.log('-------------获取到初始化信息', data)
        const {did, extendedData, gameId, gameRoleId, gameServerId, operatingActivityId, platformId, platformToken, userId, pluginVersion } = data
        this.$store.commit('COVER_UPDATA', { name: 'userInfo', data: {
          did, extendedData, gameId, gameRoleId, gameServerId, operatingActivityId, platformId, platformToken, userId
        }})
        this.$store.commit('COVER_UPDATA', {name: 'version', data: pluginVersion})
        // 获取活动配置信息
        this.getTabsInfo()
        // 打点初始化
        this.initLogger()
      })
      
      // const data = {
      //   "callbackId":"",
      //   "handlerName":"",
      //   "responseData":{
      //       did:"90E5A1677D8A391AAD1BA670CD803046",
      //       extendedData:'{"c":"125","sc":"0","extc":""}',
      //       gameId:"207567",
      //       gameRoleId:"73700013643",
      //       gameServerId:"737",
      //       operatingActivityId:"4",
      //       platformId:"2" ,
      //       platformToken:"iosSanDcjYFtl+YgiPNiHLn172XBQCMHo+CZAelqdENRI4nbtB6eoqrIYPkdPVHSL198SYozFof90gsUS+zMpVD8HOW2utt5XCuwIXzPvdtBo+4PNTyUrvrspWCz4mYLJEDkFN0ayiqh24vtpumhXTO+90BynggubvsA3oTrSJk=",
      //       userId:"66049540",
      //       pluginVersion: "1.9.0",
      //   },
      //   "responseId":"js_1_1610350172589"
      // }
      const {did, extendedData, gameId, gameRoleId, gameServerId, operatingActivityId, platformId, platformToken, userId, pluginVersion } = data.responseData
      this.$store.commit('COVER_UPDATA', { name: 'userInfo', data: {
        did, extendedData, gameId, gameRoleId, gameServerId, operatingActivityId, platformId, platformToken, userId
      }})
      this.$store.commit('COVER_UPDATA', {name: 'version', data: pluginVersion})
      this.getTabsInfo()
      this.initLogger()
    },
    getTabsInfo() {
      return this.$ajax.curl({
        url: config.baseURL + '/o/operate/activity/getOperatingActivityInfo',
        method: 'post',
        userInfo: this.$store.state.userInfo
      }).then(res => {
        const { success, message, data } = res
        console.log('========TabsInfo',res)
        if(success) {
          const { newAwardRecordNum } = data
          this.$store.commit('COVER_UPDATA', {name: 'activeInfo', data: data})
          this.$store.commit('COVER_UPDATA', {name: 'rewardNum', data: newAwardRecordNum})
          this.isRouter = true
        } else {
          this.$toast(message)
        }
        
      })
    },
    initLogger() {
      console.log('---------storage', window.localStorage)
      // 初始化打点信息
      const { userId, platformId } = this.$store.state.userInfo
      const { appId, appSecret, host, appV } = platformId == 1 ? config.eagleReport : config.report
      const { c, sc, extc } = JSON.parse(this.$store.state.userInfo.extendedData)
      const LoggerFactory = require('data-sdk/src/web/index')
      window.loggerFactory = new LoggerFactory(appId, appSecret, host)
      window.loggerFactory.setC(c || '1').setSc(sc || '').setExtc(extc || '').setAppv(appV).setUid(userId)
    }
  }
}
</script>

<style lang="scss">
@import './assets/css/index.scss';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  background-image: url('./assets/img/bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  // 导航栏样式覆盖
  .van-tabbar-item__icon {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .van-tabbar-item--active, .van-tabbar{
    background-color: transparent;
  }

  .van-info{
    right: px2rem(8px);
    top: 50%;
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

// toast样式覆盖
.van-toast{
  background-color: rgba($color: #b7433c, $alpha: 0.8);
}

.van-toast__text{
  font-family: ZCOOL-XiaoWei;
  @include font-dpr(16px);
}
</style>
