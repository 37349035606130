export const recordConfig = function recordConfig() {
  return {
    page: 'lottery',
    showKey: 'recordListShow',
    customPage: (h) => {
      const { recordList } = this
      return (
        <div class="record-list-wrapper">
          {
            recordList.map((item, index) => {
              return (
                <van-row
                  key={index}
                  class={index === 0 ? 'record-list record-list-header' : 'record-list'}
                >
                  <van-col span="6">{item.prizeRank}</van-col>
                  <van-col span="9">{item.publicTime}</van-col>
                  <van-col span="9">
                    <p>{item.gameRoleName}</p>
                    <p>{item.luckyDrawCode}</p>
                  </van-col>
                </van-row>
              )
            })
          }
        </div>
      )
    },
    customTitle: (h) => {
      return (
        <van-col class="select" span="20">
          <van-dropdown-menu overlay={false}>
            <van-dropdown-item
              value={this.period}
              options={this.periodOpt}
              onChange={this.handleChange}
            >
            </van-dropdown-item>
          </van-dropdown-menu>
        </van-col>
      )
    }
  }
}

export const countConfig = function countConfig() {
  return {
    day: this.day,
    hours: this.hours,
    minutes: this.minutes
  }
}

export const headerConfig = function headerConfig() {
  return {
    page: 'lottery',
    title: '抽奖活动倒计时',
    banner: this.bannerUrl,
    packName: '红包',
    cardName: this.$store.state.activeInfo.dzoeckrAlais,
    receiveAmount: this.receiveAmount,
    receiveDzoeckrNum: this.receiveDzoeckrNum,
    day: this.day,
    hours: this.hours,
    minutes: this.minutes,
  }
}

export const drawPicConfig = function drawPicConfig() {
  return {
    page: 'lottery-1',
    title: '',
    showKey: 'drawPicShow',
    customPage: (h) => {
      return (
        <div class="description">
          <img src={this.descriptionUrl}/>
        </div>
        
      )
    }
  }
}