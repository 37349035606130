(function () {
  if (window.WebViewJavascriptBridge) return

  var receiveMessageQueue = []
  var messageHandlers = {}

  var responseCallbacks = {}
  var uniqueId = 1

  function init (messageHandler) {
    if (WebViewJavascriptBridge._messageHandler) {
      throw new Error('WebViewJavascriptBridge.init called twice')
    }
    WebViewJavascriptBridge._messageHandler = messageHandler
    receiveMessageQueue = null
  }

  // 注册线程 往数组里面添加值
  function registerHandler (handlerName, handler, eventId) {
    if (eventId) {
      const key = handlerName + '_' + eventId
      messageHandlers[key] = handler
    } else {
      messageHandlers[handlerName] = handler
    }
  }

  // 调用线程
  function callHandler (handlerName, data, responseCallback) {
    _doSend(handlerName, data, responseCallback)
  }

  // sendMessage add message, 触发native处理 sendMessage
  function _doSend (handlerName, message, responseCallback) {
    var callbackId

    if (typeof responseCallback === 'string') {
      callbackId = responseCallback
    } else if (responseCallback) {
      callbackId = 'js_' + (uniqueId++) + '_' + new Date().getTime()
      responseCallbacks[callbackId] = responseCallback
    } else {
      callbackId = ''
    }

    var param = {
      handlerName: handlerName,
      callbackId: callbackId,
      data: message || undefined
    }

    if (typeof responseCallback === 'string' && handlerName === 'response') {
      delete param.callbackId
      delete param.data
      param.responseId = responseCallback
      param.responseData = message ? message : undefined
    }

    window.prompt(JSON.stringify(param))
  }

  // 提供给native使用,
  function _dispatchMessageFromNative (messageJSON) {
    setTimeout(function () {
      var message = JSON.parse(messageJSON)
      var responseCallback

      if (message.responseId) {
        responseCallback = responseCallbacks[message.responseId]
        if (!responseCallback) return

        var res = typeof message.responseData === 'string' ? JSON.parse(message.responseData) : message.responseData
        responseCallback(res)
        delete responseCallbacks[message.responseId]
      } else {
        // 直接发送
        if (message.callbackId) {
          var callbackResponseId = message.callbackId
          responseCallback = function (responseData) {
            _doSend('response', responseData, callbackResponseId)
          }
        }

        var handler = WebViewJavascriptBridge._messageHandler
        const res = typeof message.data === 'string' ? JSON.parse(message.data) : message.data

        if (message.handlerName) {
          if (res.spaceId) {
            var key = message.handlerName + '_' + res.spaceId
            handler = messageHandlers[key]
          } else {
            handler = messageHandlers[message.handlerName]
          }
        }
        // 查找指定handler
        try {
          handler(res, responseCallback)
        } catch (exception) {
          if (typeof console !== 'undefined') {
            console.log('WebViewJavascriptBridge: WARNING: javascript handler threw.', message, JSON.stringify(exception))
          }
        }
      }
    })
  }

  // 提供给native调用,receiveMessageQueue 在会在页面加载完后赋值为null,所以
  function _handleMessageFromNative (messageJSON) {
    console.log('handle message: ' + messageJSON)

    if (receiveMessageQueue) {
      receiveMessageQueue.push(messageJSON)
    }
    _dispatchMessageFromNative(messageJSON)
  }

  var WebViewJavascriptBridge = window.WebViewJavascriptBridge = {
    init: init,
    callHandler: callHandler, // H5调用
    registerHandler: registerHandler,
    _handleMessageFromNative: _handleMessageFromNative // android调用该接口返回数据
  }

  var doc = document
  var readyEvent = doc.createEvent('Event')
  readyEvent.initEvent('WebViewJavascriptBridgeReady')
  readyEvent.bridge = WebViewJavascriptBridge
  doc.dispatchEvent(readyEvent)
})()
