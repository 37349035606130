import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from './utils/request.js'
import Vconsole from 'vconsole'
import { Button, Tabbar, TabbarItem, Col, Row, Sticky, Field, Overlay, DropdownItem, DropdownMenu, Toast,
  RadioGroup, Radio, Loading} from 'vant'
import './assets/css/font.css'
import './assets/css/index.scss'

Toast.setDefaultOptions({ duration: 3000 })

Vue.use(Button)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Col)
Vue.use(Row)
Vue.use(Sticky)
Vue.use(Field)
Vue.use(Overlay)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Toast)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Loading)


if(process.env.NODE_ENV === 'development') {
  let vConsole = new Vconsole()
  Vue.use(vConsole)
}

Vue.prototype.$ajax = request

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
