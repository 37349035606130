import Qrcode from '@/components/Qrcode.vue'

export const headerConfig = function headerConfig() {
  const { awardBannerUrl } = this.pageConfig
  return {
    page: 'reward',
    title: '领奖结束倒计时',
    banner: awardBannerUrl,
    day: this.day,
    hours: this.hours,
    minutes: this.minutes,
    gameRoleId: this.$store.state.activeInfo.gameRoleName
  }
}

export const redPtConfig = function redPtConfig() {
  return {
    title: '红包获得明细',
    page: 'reward',
    showKey: 'redPtShow',
    customPage: (h) => {
      const { redPtList } = this
      return (
        <div class="list">
          {
            redPtList.map((item, index) => {
              const { receiveAmount, receiveTime, sourceType } = item
              return (
                <div class="list-item">
                  <div class="item-left">
                    <p class="item-title">红包获得</p>
                    <p class="item-date">{receiveTime}</p>
                  </div>
                  <div class="item-right">
                    <p class="item-title">{`${receiveAmount / 100}元`}</p>
                    <p class="item-date">{sourceType}</p>
                  </div>
                </div>
              )
            })
          }
        </div>
      )
    }
  }
}

export const giftCodeConfig = function giftCodeConfig() {
  return {
    title: '礼包码获得明细',
    page: 'reward',
    showKey: 'giftCodeShow',
    customPage: (h) => {
      const { giftCodeList } = this
      return (
        <div class="list">
          {
            giftCodeList.map((item, index) => {
              const {giftCode, receiveTime, sourceType } = item
              return (
                <div class="list-item">
                  <div class="item-left">
                    <p class="item-title">礼包码</p>
                    <p class="item-date">{receiveTime}</p>
                  </div>

                  <div>
                    <p class="item-title">{giftCode}</p>
                    <p class="item-date">{sourceType}</p>
                  </div>

                  <div onClick={(e) => this.copyCode(giftCode, '礼包码已复制')}>
                    <img class="copy-icon" src={require('@/assets/img/icon/copy-icon.png')}/>
                  </div>
                </div>
              )
            })
          }
        </div>
      )
    }
  }
}

export const drawCodeConfig = function drawCodeConfig() {
  return {
    title: `${this.$store.state.activeInfo.dzoeckrAlais}获得明细`,
    page: 'reward',
    showKey: 'drawCodeShow',
    customPage: (h) => {
      const { drawCodeList } = this
      return (
        <div class="list">
          {
            drawCodeList.map((item, index) => {
              const { dzoeckrWinningSituationDtos, luckyDrawCode, receiveTime, sourceType} = item
              let list = dzoeckrWinningSituationDtos
              if(dzoeckrWinningSituationDtos.length > 3) {
                list = dzoeckrWinningSituationDtos.slice(0,3)
              }
              return (
                <van-row class="list-item">
                  <van-col span="8" class="item-left">
                    <p class="item-title">{this.$store.state.activeInfo.dzoeckrAlais}</p>
                    <p class="item-date">{receiveTime}</p>
                  </van-col>

                  <van-col span="8">
                    {
                      list.map((dtosItem) => {
                        const {period, prizeRank} = dtosItem
                        const rankMap = {
                          0: '特等奖',
                          1: '一等奖',
                          2: '二等奖',
                          3: '三等奖',
                          4: '四等奖',
                          5: '五等奖',
                          6: '六等奖',
                          100: '幸运奖'
                        }
                        return (
                          <p class="item-date">{`第${period}期-${rankMap[prizeRank]}`}</p>
                        )
                      })
                    }
                    <p>{dzoeckrWinningSituationDtos.length > 3 ? '···' : ''}</p>
                  </van-col>
                  

                  <van-col span="8">
                    <p class="item-title">{luckyDrawCode}</p>
                    <p class="item-date">{sourceType}</p>
                  </van-col>
                </van-row>
              )
            })
          }
        </div>
      )
    }
  }
}

export const rewardConfig = function rewardConfig() {
  return {
    title: '实物大奖明细',
    page: 'reward',
    showKey: 'rewardShow',
    customPage: (h) => {
      const { rewardData } = this
      return (
        <div class="list">
          {
            rewardData.map((item, index) => {
              const { prizeItem, prizeItemNum, prizeUrl, receiveTime, sourceType } = item
              return (
                <div>
                  <div class="list-item">
                    <div class="reward-left item-left">
                      <img src={prizeUrl}/>
                      <div>
                        <p class="item-reward-title">{prizeItem}</p>
                        <p class="item-date">{receiveTime}</p>
                      </div>
                    </div>

                    <div>
                      <p class="item-title">x {prizeItemNum}</p>
                      <p class="item-date">{sourceType}</p>
                    </div>
                  </div>
                </div>
                
              )
            })
          }
          <div class="address-btn" >
            <img onClick={this.goAddress} src={require('../../assets/img/icon/address-btn.png')}></img>
          </div>
        </div>
      )
    }
  }
}

export const addressConfig = function addressConfig() {
  const config = [
    {label: '收货人', key: 'receiver', placeholder: '请填写收货人的名字'},
    {label: '联系电话', key: 'phone', placeholder: '请填写联系电话'},
    {label: '收货地址', key: 'address', placeholder: '请填写详细的收货地址'}
  ]
  return {
    title: '填写收货地址',
    page: 'reward',
    showKey: 'addressShow',
    customPage: (h) => {
      return (
        <div class="address">
          <div class="address-input">
            {
              config.map((item, index) => {
                const { label, key, placeholder } = item
                return (
                  <van-row class="item">
                    <van-col span="8" class="item-label" >{label}</van-col>
                    <van-col span="16">
                      <van-field value={this.user[key]} placeholder={placeholder} onInput={(val) => this.handleInput(val, item)}></van-field>
                    </van-col> 
                  </van-row>
                )
              })
            }
          </div>

          <div class="confirm-btn">
            <img onClick={this.setAddress} src={require('../../assets/img/icon/confirm-btn.png')}/>
          </div>

          <div class="promise-text">
            <p>温馨提示：</p>
            <p>1.实物奖品，将在活动结束后一周左右发货，请您填写准确有效的收货信息，以免发货失败；</p>
            <p>2.您的个人信息，官方承诺绝不外泄，请您放心。</p>
          </div>
        </div>
        
      )
    }
  }
}

export const withdrawConfig = function withdrawConfig() {
  return {
    title: '兑换金额',
    page: 'reward',
    showKey: 'withdrawShow',
    customPage: (h) => {
      return (
        <div class="withDraw">
          <van-radio-group class="radio-group" value={this.withdrawAmount}>
            {
              this.drawOpt.map((item, index) => {
                const { label, value } = item
                return (
                  <van-radio class="redio-item" name={value} onClick={(e) => this.clickRadio(e, value)}>
                    {label}
                    <template slot={'icon'}>
                      <img src={this.withdrawAmount === value ? require('../../assets/img/icon/radio-select.png') : require('../../assets/img/icon/radio-normal.png')}/>
                    </template>
                  </van-radio>
                )
              })
            }
          </van-radio-group>
          <div class="tips">
            <p>温馨提示</p>
            <p>{this.withdrawSweetTip}</p>
            <p>{`备注：${this.withdrawRemark}`}</p>
          </div>
          <div class="btn">
            <img onClick={this.withDraw} src={require('../../assets/img/icon/confirm-btn.png')}/>
          </div>
        </div>
      )
    }
  }
}

export const weixinConfig = function weixinConfig() {
  return {
    title: '微信绑定',
    page: 'reward',
    showKey: 'weixinShow',
    customPage: (h) => {
      // const { appCode, appName, bindCode } = this.weixinBind
      // return (
      //   <div class="weixin">
      //     <p>{`1.微信搜索："${appName}"公众号(${appCode})`}</p>
      //     <img onClick={(e) => {this.copyCode(appCode, '公众号已复制')}} src={require('../../assets/img/icon/copy-account-btn.png')}/>
      //     <p>2.复制验证码前往“海量福利”公众号输入框回复即可完成绑定操作</p>
      //     <img onClick={(e) => {this.copyCode(bindCode, '验证码已复制')}} src={require('../../assets/img/icon/copy-code-btn.png')}/>
      //     <p>*注意：游戏角色与微信账号只能绑定一次，且不可更改，红包领取需要微信绑定银行卡且完成实名认证</p>
      //   </div>
      // )

      const { appCode, appName, bindCode, qrCodeUrl } = this.weixinBind
      return (
        <div class="weixin-bind">
          <Qrcode type="image" value={qrCodeUrl}></Qrcode>

          <p class="title">如何绑定</p>
          <p class="step">1.点击下方<span class="col-span">"截图并前往微信完成绑定"</span>按钮；</p>
          <p class="step">2.打开微信进入<span class="col-span">"扫一扫"</span>，点击右上角<span class="col-span">相册</span>，选取刚才截图二维码进行识别；</p>
          <p class="step">3.识别成功后，跳转到<span class="col-span">{`"${appName}"`}</span>公众号，点击关注，即完成绑定。</p>

          <div class="button" onClick={this.bind}>
            <p>截图并前往微信完成绑定</p>
          </div>
        </div>
      )
    }
  }
}