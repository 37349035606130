<template>
  <van-overlay :show="isShow">
    <div class="container">
      <div class="lottery-count">
        <div class="title">抽奖开启倒计时</div>
        <div class="time">
          <div class="time-item" v-for="item in timeArr" :key="item.text">
            <div class="number">{{item[0]}}</div>
            <div class="number">{{item[1]}}</div>
            <div class="text">{{item.text}}</div>
          </div>
        </div>
        
      </div>
    </div>
    
  </van-overlay>
</template>

<script>
export default {
  name: 'CountDown',
  props: {
    config: {
      type: Object,
      default: {
        day: '',
        hours: '',
        minutes: ''
      }
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    timeArr() {
      const { day, hours, minutes } = this.config
      const obj = {
        day,
        hours,
        minutes
      }
      return Object.keys(obj).map(key => {
        const text = key === 'day' ? '天' : ( key === 'hours' ? '时' : '分' )
        return {
          0: obj[key].toString().substr(0, 1),
          1: obj[key].toString().substr(1, 1),
          text: text
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/index.scss';

.van-overlay{
  .container{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: #150504, $alpha: 0.2);
    .lottery-count{
      .title{
        @include font-dpr(36px);
        font-family: ZCOOL-XiaoWei;
        color: #ffffff;
        margin-bottom: px2rem(15px);
      }

      .time{
        display: flex;

        .time-item{
          display: flex;
          align-items: center;

          .number {
            background: url('../assets/img/icon/count-num-bigBg.png');
            width: px2rem(30px);
            height: px2rem(31px);
            @include font-dpr(30px);
            background-size: cover;
            font-family: ZCOOL-XiaoWei;
            color: #fff;
            margin-left: px2rem(2px);
          }

          .text{
            @include font-dpr(14px);
            color: #eb574e;
            font-family: FangZheng-ZhunYuan;
            margin-left: px2rem(2px);
          }
        }
        
      }
    }
  }
}
</style>