<template>
  <van-overlay :show="isShow">
    <div class="loading">
      <van-loading/>
    </div>
  </van-overlay>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.van-overlay{
  background-color: transparent;
  .loading{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>