<template>
  <span :class="cls"></span>
</template>

<style scoped>
  .qrcode {
    display: inline-block;
  }
</style>

<script>
import QRious from 'qrious';

export default {
  props: {
    value: {
      type: String,
      required: true,
      default: ''
    },
    size: {
      type: Number,
      default: 100
    },
    level: {
      type: String,
      default: 'M',
      validator: l => ['L', 'Q', 'M', 'H'].indexOf(l) > -1
    },
    background: {
      type: String,
      default: '#fff'
    },
    foreground: {
      type: String,
      default: '#000'
    },
    cls: {
      type: String,
      default: 'qrcode'
    },
    mime: {
      type: String,
      default: 'image/png'
    },
    padding: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: 'canvas',
      validator: t => ['canvas', 'image'].indexOf(t) > -1
    }
  },

  methods: {
    render() {
      const qr = new QRious({
        background: this.background,
        foreground: this.foreground,
        level: this.level,
        mime: this.mime,
        padding: (this.padding === 0 ? null : this.padding),
        size: this.size,
        value: this.value
      });

      this.$el.innerHTML = '';
      this.$el.appendChild(qr[this.type]);
    }
  },

  mounted() {
    this.render();
    this.$options._propKeys.forEach(key => this.$watch(key, this.render));
  }
};
</script>
